<template>
    <div>
        <Navbar
            mode="solid"
            class="navbar"
            :show-btn-icon="true"
            :show-avatar="false"
            :show-btns="false"
            :btn-link-path="backPath"
        />
        <main class="main">
            <div class="wrapper">
                <h1 class="page-title" v-t="'page.group_tour.dates.title'"/>
                <p class="page-description" v-t="'page.group_tour.dates.title'"/>
                <div class="grid gap-3">
                    <DateItem 
                        v-for="date in tour.dates" 
                        :key="date.id" 
                        :group-size="date.places_left" 
                        :date="date.tour_date"  
                        @click="startBooking(date.id, date.tour_date)" 
                    />
                </div>
            </div>
        </main>
    </div>
</template>

<script>
import Navbar from "@/components/layout/Navbar.vue";
import DateItem from "../../../components/DateItem.vue";
import { mapActions, mapGetters } from "vuex";
export default {
    name: "TourDates",
    components: {
        Navbar,
        DateItem,
    },
    methods: {
        ...mapActions({
            resetState: "tour/resetState",
            setCurrentRoute: "tour/setCurrentRoute",
            setDates:"tour/setDates",
        }),
        startBooking(dateId, tripDate) {
            this.setDates(tripDate);
            if (this.isAuthenticated === true) {
                this.$router.push(`/book/details?id=${this.$route.query.id}&date_id=${dateId}&trip_date=${tripDate}&origin=${this.$route.query.origin}`);
            }else{
                this.$router.push(`/book/login?id=${this.$route.query.id}&date_id=${dateId}&trip_date=${tripDate}&origin=${this.$route.query.origin}`);
            }
            
        },
    },
    computed: {
        ...mapGetters({tour: "tour/tour"}),
    ...mapGetters("auth", ["isAuthenticated"]),
        backPath() {
            return `/tour?id=${this.$route.query.id}`;
        },
    },
    mounted() {
        this.setCurrentRoute(this.$route.path);
    }
};
</script>

<style scoped>
.main {
    @apply pt-16 pb-32 px-4;
}

.page-title {
    @apply font-bold text-3xl text-black-base text-center;
}

.page-description {
    @apply text-black-lighter text-lg mb-12 text-center;
}

.wrapper {
    @apply relative transform -translate-x-1/2 max-w-screen-sm left-1/2;
}

.navbar{
    @apply border-none;
}
</style>