<template>
    <div class="date-tag">
        <div class="date-tag-place-container">
            <div class="date-tag-container">
                <i class="bx bxs-calendar-star bx-sm"></i>
                <time datetime="2023-01-11" class="date-tag-date-part date-tag-start">
                    <div class="date-tag-week-day">{{ startDate | date("weekday") }}</div>
                    <div class="date-tag-date">{{ startDate | date("date") }}</div>
                </time>
                <i class="bx bx-arrow-back bx-rotate-180 bx-xs"></i>
                <time datetime="2023-01-20" class="date-tag-date-part date-tag-end">
                    <div class="date-tag-week-day">{{ endDate | date("weekday") }}</div>
                    <div class="date-tag-date">{{ endDate | date("date") }}</div>
                </time>
            </div>
            <div class="divider"></div>
            <div class="date-places">
                <i class="bx bxs-user bx-sm"></i>
                {{ $tc("page.group_tour.section.dates.places_left", groupSize) }}
            </div>
        </div>
        <Button class="date-tag-btn" @click.native="$emit('click')" :text="$t('common.cta.reserve_this_date')" size="sm"
            kind="secondary" />
    </div>
</template>
<script>
import Button from "@/components/buttons/Button.vue";
export default {
    name: "DateItem",
    components: { Button },
    props: {
        date: {
            type: String,
        },
        groupSize: {
            type: Number,
            default: 36,
        },
    },
    data() {
        return {
            startDate: "01/01/1993",
            endDate: "01/01/1993",
        };
    },
    mounted() {
        let date = this.date.split("|");

        this.startDate = date[0];
        this.endDate = date[1];
    },
};
</script>
<style scoped>
.date-tag {
    @apply text-black-light;
    @apply border border-grey-base rounded-xl;
    @apply p-3 flex justify-between items-center flex-wrap sm:flex-nowrap;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.04);
}

.date-tag-place-container {
    @apply flex items-center flex-col sm:flex-row w-full sm:w-auto;
}

.date-tag-container {
    @apply flex items-center gap-4;
    @apply pb-3 sm:pb-0;
}

.date-places {
    @apply flex items-center gap-2 p-3 text-black-base text-sm font-semibold;
}

.divider {
    @apply w-full sm:h-12 sm:w-px border-grey-base border-t sm:border-t-0 sm:border-r mx-5;
}

.date-tag-week-day {
    @apply text-xs;
}

.date-tag-date {
    @apply font-semibold text-sm;
    @apply text-black-base;
}

.date-tag i:first-of-type {
    @apply text-teal-dark;
}

.date-tag-btn {
    @apply w-full sm:w-auto;
}
</style>